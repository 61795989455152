<template>
  <auth>
    <div v-show="! loading" class="right-content">
      <div class="login-header" id="main-content">
        <div class="brand">
          <div class="img-responsive mb-4">
            <img src="/logo.jpg" alt="CoOperative BEEDEE" />
          </div>
          <h4>Reset Password</h4>
        </div>
      </div>
      <hr />
      <form @submit.prevent="submit" id="reset-form">
        <div class="form-group">
          <label>New Password</label>
          <input
            v-model="password.password"
            type="password"
            class="form-control"
            name="password"
            placeholder="New Password"
          />
        </div>
        <div class="form-group">
          <label>Confirm New Password</label>
          <input
            v-model="password.password_confirmation"
            type="password"
            class="form-control"
            name="password_confirmation"
            placeholder="Confirm New Password"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary">
            Set New Password
          </button>
        </div>
      </form>
      <br />
    </div>
    <div v-if="loading" class="right-content">
      <loading />
    </div>
  </auth>
</template>

<script>
import Auth from "@/components/Auth.vue";

export default {
  components: {
    Auth,
  },

  data() {
    return {
      password: {
        code: this.$route.params.code,
        password: "",
        password_confirmation: "",
      },
      loading: false,
    };
  },

  methods: {
    submit() {
      this.loading = true;

      this.$axios
        .post("/api/password-set-new", this.password)
        .then(() => {
          this.loading = false;
          this.$toastr.s("Your password has been updated", "");
          this.$router.push({ name: "Login" });
        })
        .catch((error) => {
          this.$form.validate('reset-form', error.response)

          this.loading = false
        });
    },
  },
};
</script>

<style type="text/css">
.alert-warning p {
  color: #fd0d39;
}
</style>
